/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React from 'react';
import {
  CalendarIcon,
  // ClipboardDocumentListIcon,
  // CalendarDaysIcon,
  ClockIcon,
  UsersIcon,
  ListBulletIcon,
  UserGroupIcon,
  // RectangleGroupIcon,
  // Cog6ToothIcon,
} from '@heroicons/react/24/outline';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import {Link, useLocation} from 'react-router-dom';
import logo from '@/assets/img/logo.png';
import fwLogo from '@/assets/img/fw_logo_black.png';
import {classNames} from '@/components/utils/helper';
import AccountPopover from './AccountPopover';
import {
  EventAvailableOutlined,
  ListOutlined,
  SettingsBackupRestoreOutlined,
} from '@mui/icons-material';
import {checkRole} from '@/components/utils/roles';
import useDevice from '@/hooks/useDevice';
import {PATH_DASHBOARD} from '@/router/paths';

const navigation = [
  {
    name: 'Calendar view',
    icon: CalendarIcon,
    href: PATH_DASHBOARD.calendarOverview,
  },
  {
    name: 'Week view',
    icon: ClockIcon,
    href: PATH_DASHBOARD.timeEntries,
  },
];

const navigationDesktop = [
  {
    name: 'My time registration',
    icon: ListOutlined,
    href: PATH_DASHBOARD.calendarOverview,
  },
  {
    name: 'Predefine time entries',
    icon: SettingsBackupRestoreOutlined,
    href: PATH_DASHBOARD.predefinedEntries,
  },
];

const secondaryNavigation = [
  {
    name: 'Time approval',
    icon: EventAvailableOutlined,
    href: PATH_DASHBOARD.timeApproval,
    allowed: ['ADMIN', 'TEAM_LEAD'],
  },
  // {
  //   name: 'PL time approval',
  //   icon: EventAvailableOutlined,
  //   href: '/time/pl/approval',
  //   allowed: ['PL'],
  // },
  // {
  //   name: 'Finance time overview',
  //   icon: CalendarDaysIcon,
  //   href: '/time/finance/approval',
  //   // allowed: ['FIN', 'ADMIN'],
  // },
  // {
  //   name: 'All time entries',
  //   icon: SettingsBackupRestoreOutlined,
  //   href: PATH_DASHBOARD.timeAllEntries,
  //   allowed: ['ADMIN'],
  // },
  // {
  //   name: 'Timesheets',
  //   icon: ClipboardDocumentListIcon,
  //   href: '/timesheets',
  //   // allowed: ['ADMIN', 'FIN'],
  // },
  // {
  //   name: 'Employees week schedule',
  //   icon: Cog6ToothIcon,
  //   href: '/employee/week/schedule',
  //   // allowed: ['ADMIN', 'FIN'],
  // },
  {
    name: 'User management',
    icon: UsersIcon,
    href: PATH_DASHBOARD.userManagement,
    allowed: ['ADMIN'],
  },
  {
    name: 'Client management',
    icon: ListBulletIcon,
    href: PATH_DASHBOARD.clientManagement,
    allowed: ['ADMIN'],
  },
  {
    name: 'Team management',
    icon: UserGroupIcon,
    href: PATH_DASHBOARD.teamManagement,
    allowed: ['ADMIN'],
  },
  {
    name: 'Dashboard',
    icon: DashboardOutlinedIcon,
    href: PATH_DASHBOARD.dashboard,
    allowed: ['ADMIN', 'TEAM_LEAD'],
  },
];

export default function DesktopSideNav() {
  const location = useLocation();
  const {isTabletOrMobile} = useDevice();
  return (
    <div className="flex min-h-0 flex-1 flex-col shadow bg-white">
      <div className="flex flex-1 flex-col overflow-y-auto pt-0 pb-4">
        <div className="hidden md:flex flex-shrink-0 items-center justify-end px-4 h-16 bg-relevance-blue"></div>
        <div className="md:hidden flex flex-shrink-0 items-center justify-center px-4 h-16 bg-relevance-blue">
          {/* Profile dropdown */}
          <AccountPopover open={true} />
        </div>
        <nav
          className="mt-3 flex-1 space-y-1 bg-white px-2"
          aria-label="Sidebar"
        >
          <div className="h-16 flex items-center justify-center">
            {' '}
            <img className="h-12 w-auto" src={logo} alt="relevance" />
          </div>
          {isTabletOrMobile ? (
            <div className="space-y-1 mb-5" role="group">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    location.pathname === item.href ?
                      'bg-relevance-blue text-white hover:text-white hover:bg-relevance-blue' :
                      'text-gray-600 hover:text-white hover:bg-relevance-blue',
                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                  )}
                >
                  <item.icon
                    className={classNames(
                      location.pathname === item.href ?
                        'text-white' :
                        'text-gray-400 group-hover:text-white',
                      'mr-3 flex-shrink-0 h-6 w-6',
                    )}
                    aria-hidden="true"
                  />
                  <span className="flex-1">{item.name}</span>
                </Link>
              ))}
            </div>
          ) : (
            <div className="space-y-1 mb-5" role="group">
              {navigationDesktop.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    location.pathname === item.href ?
                      'bg-relevance-blue text-white hover:text-white hover:bg-relevance-blue' :
                      'text-gray-600 hover:text-white hover:bg-relevance-blue',
                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                  )}
                >
                  <item.icon
                    className={classNames(
                      location.pathname === item.href ?
                        'text-white' :
                        'text-gray-400 group-hover:text-white',
                      'mr-3 flex-shrink-0 h-6 w-6',
                    )}
                    aria-hidden="true"
                  />
                  <span className="flex-1">{item.name}</span>
                </Link>
              ))}
            </div>
          )}
          <div className="hidden md:block">
            <hr color="#eee" />
          </div>
          <div className="divide-t divide-gray-200 hidden md:block">
            <div className="space-y-1" role="group">
              {secondaryNavigation
                .filter((l) => checkRole(l?.allowed || []))
                .map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      location.pathname === item.href ?
                        'bg-relevance-blue text-white hover:text-white hover:bg-relevance-blue' :
                        'text-gray-600 hover:text-white hover:bg-relevance-blue',
                      'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                    )}
                  >
                    <item.icon
                      className={classNames(
                        location.pathname === item.href ?
                          'text-white' :
                          'text-gray-400 group-hover:text-white',
                        'mr-3 flex-shrink-0 h-6 w-6',
                      )}
                      aria-hidden="true"
                    />
                    <span className="flex-1">{item.name}</span>
                  </Link>
                ))}
            </div>
          </div>
        </nav>
      </div>
      <div className="flex flex-shrink-0 p-4">
        <div className="group block w-full flex-shrink-0">
          <div className="flex items-center">
            <div className="flex items-center justify-between">
              <div className="mr-1">
                <a className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                  Powered By:
                </a>
              </div>
              <img className="block h-9 ml-1" src={fwLogo} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
