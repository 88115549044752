import {SelectedTime} from '@/@types/time';
import {getISOWeek} from 'date-fns';
import React, {ReactNode, useState} from 'react';

export const SelectedTimeContext = React.createContext(null);

type SelectedTimeProps = {
  children?: ReactNode;
};

export const SelectedTimeProvider = ({children}: SelectedTimeProps) => {
  const [selectedTime, setSelectedTime] = useState<SelectedTime>({
    month: new Date(),
    week: getISOWeek(new Date()),
  });

  return (
    <SelectedTimeContext.Provider value={{selectedTime, setSelectedTime}}>
      {children}
    </SelectedTimeContext.Provider>
  );
};
