/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/authentication';
const ROOTS_DASHBOARD = '/';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  forgotPassword: path(ROOTS_AUTH, '/forgot/password'),
  resetPassword: path(ROOTS_AUTH, '/password/reset'),
  checkEmail: path(ROOTS_AUTH, '/email/sent'),
  varifyEmail: path(ROOTS_AUTH, '/email/verify'),
};

export const PATH_PAGE = {
  page404: '*',
  resetPassword: '/authentication/password/reset',
  integrationPage: '/integration/nmbrs/callback',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  calendarOverview: path(ROOTS_DASHBOARD, 'time/registration'),
  timeEntries: path(ROOTS_DASHBOARD, 'time/entries'),
  predefinedEntries: path(ROOTS_DASHBOARD, 'predefined/time'),
  timeApproval: path(ROOTS_DASHBOARD, 'time/approval'),
  timeAllEntries: path(ROOTS_DASHBOARD, 'time/entries/all'),
  userManagement: path(ROOTS_DASHBOARD, 'user/management'),
  clientManagement: path(ROOTS_DASHBOARD, 'client/management'),
  teamManagement: path(ROOTS_DASHBOARD, 'team/management'),
  dashboard: path(ROOTS_DASHBOARD, 'dashboard'),
};
