/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React, {useState, ReactNode, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import useAuth from '@/hooks/useAuth';
import {PATH_AUTH} from '@/router/paths';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({children}: AuthGuardProps) {
  const {isAuthenticated} = useAuth();
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);
  const user = window.localStorage.getItem('user');

  useEffect(() => {
    ensureAuth();
  }, [isAuthenticated]);

  const ensureAuth = () => {
    if (!user) {
      if (pathname !== requestedLocation)
        setRequestedLocation(pathname);
      return navigate(PATH_AUTH.login);
    } else
      if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null);
        return navigate(requestedLocation);
      }
  };
  return <>{children}</>;
}
