/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
/* eslint-disable new-cap */
import React, {Suspense, lazy} from 'react';
import {useRoutes, BrowserRouter, Navigate} from 'react-router-dom';
import DashboardLayout from '@/layouts/DesktopLayout';
import LogoOnlyLayout from '@/layouts/LogoOnlyLayout';
import GuestGuard from '@/guards/GuestGuard';
import AuthGuard from '@/guards/AuthGuard';
import LoadingScreen from '@/components/common/LoadingScreen';
import RoleBasedGuard from '@/guards/RoleGuard';
import {PATH_AUTH, PATH_DASHBOARD, PATH_PAGE} from './paths';
// ----------------------------------------------------------------------

const Loadable = (Component: React.ElementType) => (props: any) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

const AppRouting = () => {
  return useRoutes([
    {
      path: PATH_AUTH.root,
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: PATH_AUTH.forgotPassword,
          element: (
            <GuestGuard>
              <ForgotPassword />
            </GuestGuard>
          ),
        },
        {
          path: PATH_AUTH.resetPassword,
          element: <ResetPassword />,
        },
        {
          path: PATH_AUTH.checkEmail,
          element: (
            <GuestGuard>
              <EmailSent />
            </GuestGuard>
          ),
        },
        {
          path: PATH_AUTH.varifyEmail,
          element: (
            <GuestGuard>
              <EmailVerify />
            </GuestGuard>
          ),
        },
      ],
    },
    {path: '/', element: <Navigate to="/time/registration" replace />},
    {
      path: PATH_DASHBOARD.predefinedEntries,
      element: (
        <AuthGuard>
          <DashboardLayout>
            <RoleBasedGuard accessibleRoles={[]}>
              <PredifinedProject />
            </RoleBasedGuard>
          </DashboardLayout>
        </AuthGuard>
      ),
    },
    {
      path: PATH_DASHBOARD.calendarOverview,
      element: (
        <AuthGuard>
          <DashboardLayout>
            <RoleBasedGuard accessibleRoles={[]}>
              <CalendarOverview />
            </RoleBasedGuard>
          </DashboardLayout>
        </AuthGuard>
      ),
    },
    {
      path: PATH_DASHBOARD.timeEntries,
      element: (
        <AuthGuard>
          <DashboardLayout>
            <RoleBasedGuard accessibleRoles={[]}>
              <MyTimeEntries />
            </RoleBasedGuard>
          </DashboardLayout>
        </AuthGuard>
      ),
    },
    // {
    //   path: PATH_DASHBOARD.timeAllEntries,
    //   element: (
    //     <AuthGuard>
    //       <DashboardLayout>
    //         <RoleBasedGuard accessibleRoles={['ADMIN']}>
    //           <AllTImeEntries />
    //         </RoleBasedGuard>
    //       </DashboardLayout>
    //     </AuthGuard>
    //   ),
    // },
    {
      path: PATH_DASHBOARD.userManagement,
      element: (
        <AuthGuard>
          <DashboardLayout>
            <RoleBasedGuard accessibleRoles={['ADMIN']}>
              <UserManagement />
            </RoleBasedGuard>
          </DashboardLayout>
        </AuthGuard>
      ),
    },
    {
      path: PATH_DASHBOARD.clientManagement,
      element: (
        <AuthGuard>
          <DashboardLayout>
            <RoleBasedGuard accessibleRoles={['ADMIN']}>
              <ClientManagement />
            </RoleBasedGuard>
          </DashboardLayout>
        </AuthGuard>
      ),
    },
    {
      path: PATH_DASHBOARD.teamManagement,
      element: (
        <AuthGuard>
          <DashboardLayout>
            <RoleBasedGuard accessibleRoles={['ADMIN']}>
              <TeamManagement />
            </RoleBasedGuard>
          </DashboardLayout>
        </AuthGuard>
      ),
    },
    // {
    //   path: '/time/finance/approval',
    //   element: (
    //     <AuthGuard>
    //       <DashboardLayout>
    //         <RoleBasedGuard accessibleRoles={['ADMIN', 'FIN']}>
    //           <FinanceTimeRegistrationApproval />
    //         </RoleBasedGuard>
    //       </DashboardLayout>
    //     </AuthGuard>
    //   ),
    // },
    {
      path: PATH_DASHBOARD.timeApproval,
      element: (
        <AuthGuard>
          <DashboardLayout>
            <RoleBasedGuard accessibleRoles={['ADMIN', 'TEAM_LEAD']}>
              <TimeApproval />
            </RoleBasedGuard>
          </DashboardLayout>
        </AuthGuard>
      ),
    },
    {
      path: PATH_DASHBOARD.dashboard,
      element: (
        <AuthGuard>
          <DashboardLayout>
            <RoleBasedGuard accessibleRoles={['ADMIN', 'TEAM_LEAD']}>
              <Dashboard />
            </RoleBasedGuard>
          </DashboardLayout>
        </AuthGuard>
      ),
    },
    // {
    //   path: '/time/pl/approval',
    //   element: (
    //     <AuthGuard>
    //       <DashboardLayout>
    //         <RoleBasedGuard accessibleRoles={['PL']}>
    //           <PLTimeRegistrationApproval />
    //         </RoleBasedGuard>
    //       </DashboardLayout>
    //     </AuthGuard>
    //   ),
    // },
    // {
    //   path: '/employee/week/schedule',
    //   element: (
    //     <AuthGuard>
    //       <DashboardLayout>
    //         <RoleBasedGuard accessibleRoles={['ADMIN', 'FIN']}>
    //           <EmployeeWeekSchedule />
    //         </RoleBasedGuard>
    //       </DashboardLayout>
    //     </AuthGuard>
    //   ),
    // },
    // {
    //   path: '/timesheets',
    //   element: (
    //     <AuthGuard>
    //       <DashboardLayout>
    //         <RoleBasedGuard accessibleRoles={['ADMIN', 'FIN']}>
    //           <Timesheets />
    //         </RoleBasedGuard>
    //       </DashboardLayout>
    //     </AuthGuard>
    //   ),
    // },
    // {
    //   path: '/integration/nmbrs/callback',
    //   element: (
    //     <AuthGuard>
    //       <DashboardLayout>
    //         <RoleBasedGuard accessibleRoles={[]}>
    //           <IntegrationPage />
    //         </RoleBasedGuard>
    //       </DashboardLayout>
    //     </AuthGuard>
    //   ),
    // },
    {
      path: PATH_PAGE.page404,
      element: (
        <LogoOnlyLayout>
          <NotFound />
        </LogoOnlyLayout>
      ),
    },
  ]);
};

export default function Router() {
  return (
    <BrowserRouter>
      <AppRouting />
    </BrowserRouter>
  );
}

const Login = Loadable(lazy(() => import('@/pages/auth/Login')));
const ForgotPassword = Loadable(
  lazy(() => import('@/pages/auth/ForgotPassword')),
);
const NotFound = Loadable(lazy(() => import('@/components/common/NotFound')));
const CalendarOverview = Loadable(
  lazy(() => import('@/pages/dashboard/CalendarOverview')),
);
const MyTimeEntries = Loadable(
  lazy(() => import('@/pages/dashboard/MyTimeEntries')),
);
const UserManagement = Loadable(
  lazy(() => import('@/pages/dashboard/UserManagement')),
);
const ClientManagement = Loadable(
  lazy(() => import('@/pages/dashboard/ClientManagement')),
);
// const AllTImeEntries = Loadable(
//   lazy(() => import('@/pages/dashboard/AllTimeEntries')),
// );
// const FinanceTimeRegistrationApproval = Loadable(
//   lazy(() => import('@/pages/dashboard/finance/TimeRegistrationApproval')),
// );
const TimeApproval = Loadable(
  lazy(() => import('@/pages/dashboard/groupleader/TimeRegistrationApproval')),
);
const TeamManagement = Loadable(
  lazy(() => import('@/pages/dashboard/TeamManagement')),
);
const Dashboard = Loadable(
  lazy(() => import('@/pages/dashboard/Dashboard')),
);
// const PLTimeRegistrationApproval = Loadable(
//   lazy(() => import('@/pages/dashboard/projectleader/TimeRegistrationApproval')),
// );
// const EmployeeWeekSchedule = Loadable(
//   lazy(() => import('@/pages/dashboard/EmployeesSchedule')),
// );
// const Timesheets = Loadable(lazy(() => import('@/pages/dashboard/Timesheets')));
const ResetPassword = Loadable(
  lazy(() => import('@/pages/auth/ResetPassword')),
);
const EmailVerify = Loadable(lazy(() => import('@/pages/auth/EmailVerify')));
const EmailSent = Loadable(lazy(() => import('@/pages/auth/EmailSent')));
const PredifinedProject = Loadable(
  lazy(() => import('@/pages/dashboard/employee/PredefinedProject')),
);
// const IntegrationPage = Loadable(
//   lazy(() => import('@/pages/Integration')),
// );


