/* eslint-disable max-len */
import React from 'react';
import {createRoot} from 'react-dom/client';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles';
import {CssBaseline} from '@mui/material';
import App from '@/pages/App';
import {PageTitleProvider} from './contexts/PageTitleContext';
import {SelectedTimeProvider} from './contexts/SelectedTimeContext';
import {checkboxClasses} from '@mui/material/Checkbox';

const rootElement = document.getElementById('app');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(rootElement!);

// All `Portal`-related components need to have the the main app wrapper element as a container
// so that the are in the subtree under the element used in the `important` option of the Tailwind's config.
const theme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          zIndex: 1300,
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#CBD5E1',
          [`&.${checkboxClasses.checked}`]: {
            color: '#42ABE2',
          },
        },
      },
    },
  },
  zIndex: {
    mobileStepper: 1000,
    fab: 1050,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 130,
    snackbar: 1400,
    tooltip: 1500,
  },
});

root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <PageTitleProvider>
          <SelectedTimeProvider>
            <App />
          </SelectedTimeProvider>
        </PageTitleProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
);
