export type Role = {
  id: number;
  code_name: string;
};

export const checkRole = (allowedRoles: string[]) => {
  const loggedInUser = JSON.parse(localStorage.getItem('user'));
  const userRoles: string[] = [];
  userRoles.push(loggedInUser?.role);

  if (loggedInUser?.is_team_leader === true) userRoles.push('TEAM_LEAD');

  if (allowedRoles.length === 0) return true;

  return userRoles.some((role) => allowedRoles.includes(role));
};
