/* eslint-disable @typescript-eslint/no-explicit-any */
import useLocales from '@/hooks/useLocales';
import React, {ReactNode, useState} from 'react';
import {toast} from 'react-toastify';

export const ErrorContext = React.createContext(null);

type ErrorProps = {
  children?: ReactNode;
};

export const ErrorProvider = ({children}: ErrorProps) => {
  const [error, setError] = useState<any>();
  const {translate} = useLocales();
  // error === {key: value((value) -> array, {key: value} ,string)}
  const handleErrors = (error: any) => {
    for (const key in error)
      if (Object.prototype.hasOwnProperty.call(error, key)) {
        const element = error[key];
        // value === string
        if (typeof element === 'string')
          return toast.error(`${key}: ${translate(error[key])}`, {
            toastId: key,
          });
        // value === Array
        if (Array.isArray(element))
          element.forEach((ele: any) => {
            if (typeof ele === 'string')
              toast.error(`${key}: ${translate(ele)}`, {toastId: key});
            else handleErrors(ele);
          });
        else handleErrors(error[key]);
      }
    return setError(error);
  };
  return (
    <ErrorContext.Provider value={{error, setError, handleErrors}}>
      {children}
    </ErrorContext.Provider>
  );
};
