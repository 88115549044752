import jwtDecode from 'jwt-decode';
import {verify, sign} from 'jsonwebtoken';
//
import axios from '@/apis/axiosHttp';

// ----------------------------------------------------------------------

const isValidToken = (accessToken: string) => {
  if (!accessToken)
    return false;

  const decoded = jwtDecode<{exp: number}>(accessToken);
  const currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};

const setSession = (accessToken: string | null) => {
  if (accessToken) {
    window.localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    window.localStorage.removeItem('accessToken');
    window.localStorage.removeItem('user');
    delete axios.defaults.headers.common.Authorization;
  }
};

export {isValidToken, setSession, verify, sign};
