import env from '@/constants/env';
import axios from 'axios';

// Make an 'instance' of axios
const axiosHttp = axios.create({
  // where we make our configurations
  baseURL: env.ApiServerUrl || window.location.origin,
});

// Add configure interceptors && all the other cool stuff
axiosHttp.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      const newErr = error;

      // eslint-disable-next-line curly
      if (error.response.data.code === 'token_not_valid') {
        newErr.response.data = {
          message: 'expired_session_logging_off',
        };
        location.reload();
      }
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(newErr as unknown as Error);
    }

    if (error.response.status === 404) {
      const newErr = error;

      newErr.response.data = {
        message: 'route_404',
      };

      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(newErr as unknown as Error);
    }

    if (error.response.status === 500) {
      const newErr = error;

      newErr.response.data = {
        message: 'server_error_msg',
      };

      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(newErr as unknown as Error);
    }

    if (error.response.status === 503) {
      const newErr = error;

      newErr.response.data = {
        message: 'server_error_msg',
      };

      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(newErr as unknown as Error);
    }

    return Promise.reject(error);
  },
);

export default axiosHttp;
