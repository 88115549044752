/* eslint-disable max-len */
import {useMediaQuery} from 'react-responsive';

const useDevice = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)',
  });
  const isBigScreen = useMediaQuery({query: '(min-width: 1824px)'});
  const isTabletOrMobile = useMediaQuery({query: '(max-width: 1024px)'});
  const isPortrait = useMediaQuery({query: '(orientation: portrait)'});
  const isRetina = useMediaQuery({query: '(min-resolution: 2dppx)'});

  return {isBigScreen, isDesktopOrLaptop, isPortrait, isRetina, isTabletOrMobile};
};

export default useDevice;
