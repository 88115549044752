/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable require-jsdoc */
import React, {ReactNode, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import {PATH_DASHBOARD} from '@/router/paths';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({children}: GuestGuardProps) {
  const {isAuthenticated} = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    checkAuth();
  }, [isAuthenticated]);

  const checkAuth = () => {
    if (isAuthenticated)
      return navigate(PATH_DASHBOARD.root);
  };
  return <>{children}</>;
}
