import React from 'react';
import '@/assets/styles/style.css';
import '@/assets/styles/app.scss';
import {AuthProvider} from '@/contexts/JWTContext';
import Router from '@/router';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ErrorProvider} from '@/contexts/ErrorContext';
import ThemeLocalization from '@/components/common/ThemeLocalization';
import '@/contexts/i18n';

const App: React.FC = () => {
  return (
    <div className="min-h-full">
      <ThemeLocalization>
        <AuthProvider>
          <ErrorProvider>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={true}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable={false}
              pauseOnHover
              theme="light"
            />
            <Router />
          </ErrorProvider>
        </AuthProvider>
      </ThemeLocalization>
    </div>
  );
};

export default App;
