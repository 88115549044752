/* eslint-disable max-len */
import React, {Fragment} from 'react';
import {Menu, Transition} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/20/solid';
import userpic from '@/assets/img/user.png';
import {classNames} from '@/components/utils/helper';
import useAuth from '@/hooks/useAuth';
// import {toast} from 'react-toastify';

type AccountPopoverProps = {
    open?: boolean;
};

const AccountPopover: React.FC<AccountPopoverProps> = ({open}:AccountPopoverProps) => {
  const {logout} = useAuth();
  const user = JSON.parse(localStorage.getItem('user'));
  const handleSubmit = () => {
    logout();
    // toast.info('Logged out!');
  };
  return (
    <Menu as="div" className="relative ml-3">
      <div>
        <Menu.Button className="flex items-center justify-between p-1 rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-relevance-blue focus:ring-offset-2">
          <img className="h-8 w-8 rounded-full" src={userpic} alt="" />
          <span className="pl-1 block">{user?.first_name} {user?.last_name}</span>
          <ChevronDownIcon
            className={classNames(
              open ? 'text-gray-600' : 'text-gray-400',
              'ml-2 h-5 w-5 group-hover:text-gray-500',
            )}
            aria-hidden="true"
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item>
            <span
              className={classNames(
                'bg-relevance-blue block px-4 py-2 text-sm text-white ',
              )}
            >
              {user?.email?.length > 20 ? user?.email?.substring(0, 20) + '...' : user?.email}
            </span>
          </Menu.Item>
          <Menu.Item>
            {({active}) => (
              <a
                href="#"
                onClick={() => handleSubmit()}
                className={classNames(
                  active ? 'bg-white' : '',
                  'block px-4 py-2 text-sm text-gray-700',
                )}
              >
                Sign out
              </a>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default AccountPopover;
