/* eslint-disable no-unused-vars */
export enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER'
}

export type RowsState = {
  page: number;
  pageSize: number;
  loading: boolean;
};

export type Category = {
  id: number;
  name: string;
  sub_category: string;
};

export type Client = {
  id: number;
  name: string;
  navision_id: string;
};
