/* eslint-disable require-jsdoc */
import React, {ReactNode} from 'react';
import {Container, Alert, AlertTitle} from '@mui/material';
import {checkRole} from '@/components/utils/roles';

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  accessibleRoles: string[];
  children: ReactNode | string;
};

export default function RoleBasedGuard({
  accessibleRoles,
  children,
}: RoleBasedGuardProp) {
  if (!checkRole(accessibleRoles))
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          You do not have permission to access this page
        </Alert>
      </Container>
    );


  return <>{children}</>;
}
