import React from 'react';

const LoadingScreen: React.FC = () => {
  return (
    <section className='h-full w-full flex align-middle justify-center'>
      <div className="lds-facebook my-auto">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </section>
  );
};

export default LoadingScreen;
