/* eslint-disable require-jsdoc */
/* eslint-disable max-len */

import React from 'react';
import {Disclosure} from '@headlessui/react';
import Logo from '@/assets/img/Relevance_Logo_Blue_Pos_RGB.svg';
import AccountPopover from './AccountPopover';
import {usePageTitle} from '@/hooks/usePageTitle';

export default function DesktopTopNav() {
  const {pageTitle} = usePageTitle();
  return (
    <Disclosure as="nav" className="w-full bg-relevance-blue z-9">
      {({open}) => (
        <>
          <div className="mx-auto max-w-7xl pr-4 sm:px-6 lg:px-8">
            <div className="flex h-16 items-center justify-between">
              <div className='md:hidden'>
                <h3 className='m-0 p-0 text-white font-bold text-lg'>{pageTitle}</h3>
              </div>
              <div className="flex ">
                <div className="flex flex-shrink-0 items-center ">
                  <span className="bg-white py-1 px-3 flex items-center justify-center rounded md:hidden">
                    <div className="h-auto w-16 block sm:hidden">
                      <img src={Logo} alt="Van Hoek" />
                    </div>
                    <div className="h-12 w-auto hidden sm:block">
                      <img src={Logo} alt="Van Hoek" />
                    </div>
                  </span>
                </div>
              </div>
              <div className="ml-6 md:flex items-center hidden ">
                {/* Profile dropdown */}
                <AccountPopover open={open} />
              </div>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
}
