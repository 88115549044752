/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
import React, {createContext, ReactNode, useEffect, useReducer} from 'react';
import axiosHttp from '@/apis/axiosHttp';
import {isValidToken, setSession} from '@/components/utils/jwt';
import {
  ActionMap,
  AuthState,
  AuthUser,
  JWTContextType,
} from '@/@types/authentication';
import {Types} from '@/@types/enum';
import {AxiosResponse, AxiosError} from 'axios';
import {toast} from 'react-toastify';

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions =
  ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
  case 'INITIALIZE':
    return {
      isAuthenticated: action.payload.isAuthenticated,
      isInitialized: true,
      user: action.payload.user,
    };
  case 'LOGIN':
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  case 'LOGOUT':
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };

  case 'REGISTER':
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };

  default:
    return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({children}: {children: ReactNode}) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          await axiosHttp
            .get('/api/auth/user')
            .then(async (response: AxiosResponse) => {
              const user = response.data;
              if (response.status === 401) {
                toast.warning('Session expired!');
                logout();
              }
              dispatch({
                type: Types.Initial,
                payload: {
                  isAuthenticated: true,
                  user,
                },
              });
            })
            .catch((error) => {
              logout();
              return Promise.reject(error);
            });
        } else logout();
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = (email: string, password: string) => {
    return axiosHttp
      .post('/api/auth/token/', {
        email,
        password,
      })
      .then(async (response: AxiosResponse) => {
        const {access} = response.data;
        setSession(access);
        const {data} = await axiosHttp.get('/api/auth/user/');
        if (!data) logout();
        window.localStorage.setItem('user', JSON.stringify(data));
        dispatch({
          type: Types.Login,
          payload: {
            user: data,
          },
        });
        return response;
      })
      .catch((error: AxiosError) => {
        return Promise.reject(error);
      });
  };

  const register = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
  ) => {
    const response = await axiosHttp.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const {accessToken, user} = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: Types.Register,
      payload: {
        user,
      },
    });
  };

  const logout = () => {
    setSession(null);
    dispatch({type: Types.Logout});
  };

  const forgotPassword = (email: string) => {
    return axiosHttp
      .post('/api/auth/password/reset/', {
        email,
      })
      .then(async (response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        return Promise.reject(error);
      });
  };

  const resetPassword = (
    newPassword: string,
    confirmNewPassword: string,
  ) => {
    return axiosHttp
      .patch('/api/auth/user/change_password/', {
        new_password1: newPassword,
        new_password2: confirmNewPassword,
      })
      .then(async (response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        return Promise.reject(error);
      });
  };

  const resetEmailPassword = (
    newPassword: string,
    confirmNewPassword: string,
    uidb64: string,
    token: string,
  ) => {
    return axiosHttp
      .post('/api/auth/password/reset/confirm/', {
        new_password1: newPassword,
        new_password2: confirmNewPassword,
        uid: uidb64,
        token: token,
      })
      .then(async (response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        return Promise.reject(error);
      });
  };

  const updateProfile = (userDetails: unknown) => {
    return axiosHttp
      .patch('/api/auth/user/', {
        userDetails,
      })
      .then(async (response: AxiosResponse) => {
        const {data} = response;
        if (!data) logout();
        window.localStorage.setItem('user', JSON.stringify(data));
        dispatch({
          type: Types.Login,
          payload: {
            user: data,
          },
        });
        return response;
      })
      .catch((error: AxiosError) => {
        return Promise.reject(error);
      });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        resetPassword,
        resetEmailPassword,
        forgotPassword,
        updateProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export {AuthContext, AuthProvider};
